@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700");

body {
  margin: 0;
  font-family: "Montserrat", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
}

*,
::after,
::before {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  text-decoration: none;
  color: #fff;
}

h1,
h1,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ol,
ul,
dl {
  margin: 0;
}

button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

button:focus {
  outline: none;
}
